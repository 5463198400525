<script>
  import api from "@/api"
  import EventBus from "@/eventbus"
  import VueRecaptcha from 'vue-recaptcha'

  export default {
    name: "mail-add",
    data: function() {
      return {
        isLoading: false,
        mails: [],
        mailAddress: "",
        loadingState: false,
        captchaResponse: "",
        showSuccessMessage: false
      }
    },
    components: { VueRecaptcha },
    created() {
      this.$store.dispatch("resetQuestionnaireState")
      this.$store.dispatch("pushPage", this.$route.name)
      if (!(["mail", "quest-mail", "quest-structures-auth"]).includes(this.$store.getters.previousPage)) {
        this.$router.replace({name: "home"}) 
      } else {
        this.$store.dispatch("setBackLogic", () => {
          this.$router.replace({name: this.$store.getters.previousPage})
        })

        this.isLoading = true
        api.getMails().then((response) => {
          this.mails = response.data.addresses
        }).catch(() => {
          EventBus.$emit("errorMessage", "Impossibile reperire le tue e-mail")
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
    methods: {
      onSubmit() {
        if (this.captchaResponse == "") {
          EventBus.$emit("errorMessage", "Per favore, clicca su ‘Non sono un robot")
        } else if (this.mails.includes(this.mailAddress)) {
          EventBus.$emit("errorMessage", "Indirizzo email già registrato")
        } else {         
          this.loadingState=true
          api.newMailRequest(this.mailAddress, this.captchaResponse).then(response => {
            console.log(response)
            if (response.status == 204) {
              if ((["quest-mail", "quest-structures-auth"]).includes(this.$store.getters.previousPage))
                this.$store.dispatch("saveSessionAndDeleteState")

              this.$store.dispatch("disableBackButton")
              this.showSuccessMessage = true
            } else 
              EventBus.$emit("errorMessage", "L’indirizzo e-mail inserito non è valido. Per favore, controlla l’indirizzo inserito.")
          }).catch(() => {
            EventBus.$emit("errorMessage", "L’indirizzo e-mail inserito non è valido. Per favore, controlla l’indirizzo inserito.")
          })
          .then(() => this.loadingState=false)
        }
      },
      verifyCaptcha(response) {
        this.captchaResponse = response
      },

			expireCaptcha() {
				this.$refs.mailAddRecaptcha.reset()
        this.captchaResponse = ""
			},
      errorCaptcha() {
        EventBus.$emit("errorMessage", "Errore di caricamento del componente. Verifica di essere connesso ad internet")
      }
    }
  }
</script>

<template>
  <div class="my-is-under-navbar">
    <div class="section" >
      <div class="columns is-centered" v-if="isLoading"> 
        <div class="column is-4-desktop">
          <div class="box is-flex is-flex-direction-column is-align-items-center section">
            <div class="block has-text-centered">
              <h1 class="title is-3">
                {{ $t("loadingTitle") }}
              </h1>
              <h2 class="subtitle is-5">
                {{ $t("loadingSubtitle") }}
              </h2>
            </div>
            <div class="loader is-loading my-loader"></div>
          </div>
        </div>
      </div>
      <div class="columns is-centered" v-if="!showSuccessMessage && !isLoading">
        <div class="column is-5-desktop">
          <div class="block">
            <h1 class="title is-3">
              {{ $t("title") }}
            </h1>
          </div>

          <div class="block">
            <div class="message is-info mb-1">
              <div class="message-body">
                {{ $t("message1") }}
              </div>
            </div>
            <div class="message is-info mb-1">
              <div class="message-body">
                {{ $t("message2") }}
              </div>
            </div>
          </div>
        
          <form v-on:submit.prevent="onSubmit()"> 
            <div class="block">
              <div class="field">
                <p class="control is-expanded">
                  <input class="input is-medium is-expanded" required type="text" placeholder="E-mail" 
                    v-model="mailAddress" v-bind:disabled="loadingState">
                </p>
              </div>
            </div>

            <div class="block">
              <div class="level">
                <div class="level-item">  
                  <vue-recaptcha sitekey="6LfNvQcaAAAAACSu-EOmV_MAJxz8LyY19hkDi1ae" 
                    v-on:verify="verifyCaptcha"
                    v-on:expired="expireCaptcha"
                    v-on:error="errorCaptcha"
                    :loadRecaptchaScript="true"
                    ref="mailAddRecaptcha"></vue-recaptcha>
                </div>
              </div>
            </div>  

            <div class="block">
              <div class="field">
                <p class="control">
                  <button class="button is-medium is-info is-fullwidth" v-bind:class="{'is-loading': loadingState}">
                    <span>{{ $t("addButton") }}</span>
                  </button>
                </p>
              </div>
            </div>
          </form>
        </div>  
      </div> 
      <div class="columns is-centered" v-if="showSuccessMessage && !isLoading">
        <div class="column is-5-desktop">
          <div class="block">
            <div class="notification is-success is-light has-text-centered">
              <div class=" p-5">
                <span class="icon has-text-success is-large ">
                  <i class="fas fa-3x fa-check"></i>
                </span>
                <p class="is-size-4">{{ $t("successMessage") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
  </div>
</template>

<i18n>
{
  "it": {
    "message1": "Il tuo indirizzo e-mail sarà utilizzato unicamente per inviarti il codice di accesso ai luoghi in cui vuoi recarti.",
    "message2": "TRAC19 memorizza il tuo indirizzo e-mail esclusivamente su questo dispositivo per velocizzare la compilazione dei successivi questionari.",
    "title": "Registra una nuova e-mail",
    "addButton": "Registra",
    "successMessage": "Controlla la casella di posta e clicca sul link che ti abbiamo inviato per confermare il tuo indirizzo e-mail",
    "loadingTitle": "Caricamento e-mail",
    "loadingSubtitle": "L'operazione dovrebbe richiedere qualche istante"
  }
}
</i18n>

<style>
</style>